const formElements = document.querySelectorAll('.form__input, .form__textarea');

['blur', 'focus'].forEach((evt) => {
  formElements.forEach((element) => {
    element.addEventListener(
      evt,
      () => {
        if (element.value) {
          element.previousElementSibling.classList.add('-filled');
        } else {
          element.previousElementSibling.classList.remove('-filled');
        }
      },
      false
    );
  });
});

formElements.forEach((element) => {
  element.addEventListener(
    'focus',
    () => {
      if (element) {
        element.previousElementSibling.classList.add('-filled');
      } else {
        element.previousElementSibling.classList.remove('-filled');
      }
    },
    false
  );
});
